/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css?family=Roboto");
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// Comment after check
//@import "../node_modules/@videogular/ngx-videogular/fonts/videogular.css";
// End
@import "custom.scss";

/*  files styles cropCalendar Module */
@import url("../src/assets/assets-fao/sass/abstracts/_variables.scss");

html,
body {
  margin: 0px;
  padding: 0px;
  font-family: "Roboto";
}

mat-sidenav-container,
.my-content {
  margin: 0;
  width: 100%;
  height: 100%;
}

mat-toolbar.mat-toolbar {
  position: fixed;
  z-index: 1030;
  top: 0;
  padding: 0 10px;
}

.my-content {
  overflow: auto;
}

.example-fab {
  position: absolute;
  right: 20px;
  bottom: -80vh;
}

.toolbar-placeholder-fix {
  height: 64px;
}

div {
  // &.cdk-overlay-connected-position-bounding-box{
  //     top: 0 !important;
  //     left: 0 !important;
  //     background: rgba(0, 0, 0, 0.2) !important;
  //     width: 100% !important;
  //     height: 100vh !important;
  // }
  // &.cdk-overlay-container {
  //     z-index: 10000 !important;
  // }
  // &.mat-menu-panel{
  //     border-radius: 0;
  //     max-height: 100vh;
  //     width: 275px;
  // }
  // &.cdk-overlay-pane {
  //     > div.mat-menu-panel {
  //         height: 100vh !important;
  //         left: 0 !important;
  //     }
  // }
  // &.cdk-overlay-pane{
  //     //left: 0 !important;
  // }

  &.profilePage {
    padding: 0px;

    .formModule {
      padding: 15px;
    }
  }

  &.formModule {
    display: table;
    width: 100%;
    height: 100%;
    /* background: #3F51B5; */

    .displayTableCell {
      display: table-cell;
      vertical-align: middle;
      width: 100%;
      height: 100%;
    }

    h3 {
      text-align: center;
      font-size: 22px;
      margin: 0 0 20px;
    }

    .authBlock {
      margin: 0 auto;
      max-width: 400px;
      background: white;
      padding: 30px 40px 10px;
      overflow: hidden;
      -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.04);
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }

    label {
      display: block;
      font-size: 13px;
      padding-bottom: 5px;
      font-weight: 600;
    }

    .formGroup {
      margin-bottom: 20px;
      float: left;
      width: 100%;
    }

    .formControl {
      width: 100%;
      display: block;
      padding: 15px 15px 14px;
      border: 2px solid #e7e7e7;
      outline: none;
      font-size: 15px;
      color: #444444;
      background: #fcfcfc;
    }

    .formControl:focus {
      border: 2px solid #d3d3d6;
    }

    input::-webkit-input-placeholder {
      color: #bbbbbb;
    }

    input::-moz-placeholder {
      color: #bbbbbb;
    }

    input:-ms-input-placeholder {
      color: #bbbbbb;
    }

    input:-moz-placeholder {
      color: #bbbbbb;
    }

    .btn {
      width: 100%;
      border: none;
      font-size: 16px;
      font-weight: 600;
      padding: 15px 0;
      background: #03a9f4;
      color: #ffffff;
      cursor: pointer;
      outline: none;
    }

    .btn:hover {
      opacity: 0.88;
    }

    .btnSecondary {
      background: #eeeeee;
      color: #404040;
    }

    .googleBtn {
      background: #eb5e4c;
    }

    .facebookBtn {
      background: #5d82d1;
    }

    .or {
      text-align: center;
      display: block;
      color: #a0a0a0;
      background: white;
      position: relative;
      margin: 5px 0 0px;
    }

    .orInner {
      background: white;
      display: inline-block;
      z-index: 4;
      position: relative;
      padding: 0 12px;
    }

    .or:before {
      position: absolute;
      content: "";
      left: 0;
      top: 11px;
      width: 100%;
      height: 1px;
      background: #e2e2e2;
    }

    .halfWidth {
      width: 48.5%;
    }

    .left {
      float: left;
    }

    .right {
      float: right;
    }

    .forgotPassword {
      text-align: center;
      margin: -12px 0 15px 0;
      float: left;
      width: 100%;

      span {
        color: #3c89ef;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        display: inline-block;
        padding-top: 20px;
      }
    }

    .redirectToLogin {
      padding: 15px 0;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      display: block;
      color: rgba(255, 255, 255, 0.6);

      .redirect {
        cursor: pointer;
        color: #ffffff;
        text-decoration: underline;
      }
    }
  }
}

/* Responsive */
@media (max-width: 767px) {
  div {
    .formModule {
      background: white;
      height: 100vh;

      .authBlock {
        box-shadow: none;
      }
    }

    .media {
      display: block;
    }

    .rounded-circle {
      max-width: 150px;
      margin: 0 auto 20px !important;
      display: block;
    }
  }
}

.mat-simple-snackbar-action {
  color: white;
  border: 1px solid white;
}

.displayNone {
  display: none !important;
}

.displayFlex {
  display: flex !important;
}

.overFlowScroll {
  overflow: scroll !important;
}

.overFlowInitial {
  overflow: initial !important;
}

.overFlowHidden {
  overflow: hidden !important;
}

/* @media  only screen and (max-width: 1024px) {
    .mat-tab-header-pagination {
    display: flex !important;
    }
    .mat-tab-header {
        overflow: hidden !important;
    }
    .mat-tab-header::-webkit-scrollbar {
        display: flex !important;
    }
    .mat-tab-label-container {
        overflow: hidden !important;
    }
}
@media  only screen and (orientation: portrait) and (min-width: 320px) and (max-width: 1024px) {
    .mat-tab-header-pagination {
    display: none !important;
    }
    .mat-tab-header {
        overflow: scroll !important;
    }
    .mat-tab-header::-webkit-scrollbar {
        display: none !important;
    }
    .mat-tab-label-container {
        overflow: initial !important;
    }
}
 @media  only screen and (orientation: landscape) and (min-width: 320px) and (max-width: 1024px) {
    .mat-tab-header-pagination {
    display: none !important;
    }
    .mat-tab-header {
        overflow: scroll !important;
    }
    .mat-tab-header::-webkit-scrollbar {
        display: none !important;
    }
    .mat-tab-label-container {
        overflow: initial !important;
    }
} */

@media (max-width: 600px) {
  .toolbar-placeholder-fix {
    height: 54px;
  }
}

.text-center {
  text-align: center;
}

.grey {
  background-color: #f5f5f5;
}

.darkgrey {
  background-color: #eee;
}

.center {
  text-align: center;
}

.content {
  padding-top: 62px;
}

.loading {
  margin: auto;
  padding-top: 64px;
}

@media (max-width: 600px) {
  .loading {
    padding-top: 60px;
  }
}

mat-tab-group {
  margin-top: 64px;
}

@media (max-width: 600px) {
  mat-tab-group {
    margin-top: 54px;
  }
}

@media (max-width: 425px) {
  .splash {
    width: 307px;
    height: 85px;
  }
}

@media (min-width: 768px) {
  .splash {
    width: 614px;
    height: 170px;
  }
}

.test {
  text-align: center;
}

.margin-center {
  margin-left: auto;
  margin-right: auto;
}

.object-center {
  display: block !important;
  margin: 10px auto !important;
}

.m-t-4 {
  margin: 4px 0;
}

.white-text {
  color: white !important;
}

.toolbar-flex {
  flex: 1 1 aut;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.menu-icon {
  background-repeat: no-repeat;
  display: inline-block;
  fill: currentColor;
  height: 24px;
  width: 24px;
}

.toolbar-back-button {
  position: absolute;
  left    : 7px;
  top: 0px;
  padding-right: 1rem !important;
}

.menu-margin {
  margin-right: 5px;
  margin-left: 5px;
}

/* New menu */
.sMenu+* .cdk-overlay-connected-position-bounding-box {
  top: 0 !important;
  left: 0 !important;
  background: rgba(0, 0, 0, 0.2) !important;
  width: 100% !important;
  height: 100vh !important;
}

.sMenu+* .cdk-overlay-container {
  position: relative !important;
  z-index: 100000 !important;
}

.sMenu+* .mat-menu-panel {
  border-radius: 0;
  max-height: 100vh;
  width: 275px;
}

.sMenu+* .cdk-overlay-pane {
  height: 100vh !important;
  left: 0 !important;
  top: 55px !important;
}

.sMenu+* .cdk-overlay-pane {
  >div.mat-menu-panel {
    height: 100vh !important;
  }

  .mat-menu-item {
    h4 {
      width: 200px;
      text-overflow: ellipsis;
      max-width: 216px;
      overflow: hidden;
    }
  }
}

mat-grid-list.responsiveMat .mat-grid-tile .mat-figure {
  display: inherit !important;
  padding: 10px
}

.mat-list-item-content .mat-list-text,
.mat-list-base .mat-list-item.mat-list-option .mat-list-item-content .mat-list-text,
.mat-list-base .mat-list-option.mat-list-item-with-avatar .mat-list-item-content .mat-list-text,
.mat-list-base .mat-list-option.mat-list-option .mat-list-item-content .mat-list-text {
  padding-right: 16px !important;
}

@media (max-width: 767px) {
  .forumToolBarSpace {
    height: 50px !important
  }
}

.user-name:hover {
  /* color          : #7cb342; */
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}

.user-image-hover:hover {
  cursor: pointer;
  background-color: lightgray;
}
.highlight {
  display: inline;
  padding: .25em 0;
  background: #FFC107;
  color: #ffffff;
  box-shadow: .5em 0 0 #FFC107, -.5em 0 0 #FFC107;
}
