:root{
    --fao-white: #fff;
    --fao-black: #000;
    --fao-gray: #f1f1f1;
    --fao-gray-line: #dadada;
    --fao-gray-medium:#d8d8d8;
    --fao-gray-light: #ccc;
    --fao-gray-shadow: #bbc2c8;
    --fao-gray-dark: #454545;

     /* Corporate colors */
    --fao-color: #0d6dac;
    --fao-color-dark: #0a5282;
    --fao-link: #1170ae;
    --fao-line-language: #5f8dab;
    --fao-border: #96c0db;
    --fao-border-light: #b8d5ea;
    --fao-bg-filter: #e5f3fd;
    --fao-sowing: #6db753;
    --fao-harvest:  #314a5e;

    --fao-text: #979797;
    --fao-text-dark:#6d6d6d;
    --fao-text-darker: #636363;

    --fao-disabled:#b5d2e5e5;
    --fao-hover:#0f7bc5;


}